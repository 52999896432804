var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "title": "Form APK Versioning",
      "id": "form-modal",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary",
            "disabled": !_vm.isValidForm
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v("Simpan")])];
      },
      proxy: true
    }])
  }, [_c('b-form-group', {
    staticClass: "mb-2",
    attrs: {
      "label": "Judul"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.form.judul,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "judul", $$v);
      },
      expression: "form.judul"
    }
  })], 1), _c('b-form-group', {
    staticClass: "mb-2",
    attrs: {
      "label": "Versi"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.form.versi,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "versi", $$v);
      },
      expression: "form.versi"
    }
  })], 1), _c('b-form-group', {
    staticClass: "mb-2",
    attrs: {
      "label": "File APK"
    }
  }, [_c('input', {
    attrs: {
      "type": "file"
    },
    on: {
      "change": _vm.onChooseFile
    }
  })]), _c('b-form-group', {
    staticClass: "mb-2",
    attrs: {
      "label": "Tanggal Update"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.form.tgl_update,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tgl_update", $$v);
      },
      expression: "form.tgl_update"
    }
  })], 1), _c('b-form-group', {
    staticClass: "mb-2",
    attrs: {
      "label": "Keterangan (opsional)"
    }
  }, [_c('b-form-textarea', {
    model: {
      value: _vm.form.keterangan,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "keterangan", $$v);
      },
      expression: "form.keterangan"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }