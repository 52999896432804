<template>
  <b-overlay :show="loading">
    <form-modal @submit="submit"></form-modal>
    <b-card>
      <b-row>
        <b-col md="6" sm="12" class="my-1">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            @click.prevent="add"
            variant="primary"
            v-if="allowCreate()"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            Tambah
          </b-button>
        </b-col>
        <b-col md="6" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
  
        <b-col cols="12">
          <b-table
            striped
            small
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            
          >
            <template #cell(no)="{index}">
              {{ ++index }}
            </template>
            <template #cell(path)="{item}">
              <a v-if="item.path" :href="item.path" target="_blank" class="btn btn-primary btn-sm">
                <feather-icon icon="DownloadIcon"></feather-icon>
                Download
              </a>
              <i v-else class="text-danger">APK belum diupload</i>
            </template>
            <template #cell(actions)="{item}">
              <b-button
                  v-b-tooltip.hover.right="'Ubah'"
                  size="sm"
                  @click="edit(item)"
                  class="mr-1"
                  variant="outline-info"
                >
                  <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                  v-b-tooltip.hover.right="'Hapus'"
                  size="sm"
                  @click="remove(item)"
                  class="mr-1"
                  variant="outline-danger"
                >
                  <feather-icon icon="TrashIcon" />
              </b-button>
            </template>
          </b-table>
        </b-col>
  
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>
  
  <script>
  import Ripple from 'vue-ripple-directive'
  import FormModal from './components/FormModal.vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import {
    BTable,
    BOverlay,
    VBTooltip,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
  } from "bootstrap-vue";
  
  export default {
    components: {
      BOverlay,
      FormModal,
      BCard,
      ToastificationContent,
      VBTooltip,
      BModal,
      BDropdown,
      BDropdownItem,
      BTable,
      BAvatar,
      BBadge,
      BRow,
      BCol,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      BFormCheckbox ,
    },
    directives: {
      "b-tooltip": VBTooltip,
      Ripple,
    },
    data() {
      return {
        id:null,
        loading: false,
        form: {
          blok : null,
          gudang_id : null,
        },
        perPage: 10,
        pageOptions: [10, 30, 50],
        totalRows: 1,
        currentPage: 1,
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        fields: [
          {
            key: "no",
            label: "No",
          },
          { key: "judul", label: "Judul", sortable: true },
          { key: "versi", label: "Versi APK", sortable: true },
          { key: "path", label: "File APK", sortable: true },
          { key: "tgl_update", label: "Tanggal Update", sortable: true },
          { key: "keterangan", label: "Keterangan", sortable: true },
          { key: "actions", label: "" }
        ],
        items: []
      };
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => ({ text: f.label, value: f.key }));
      },
    },
    methods: {
      add() {
        this.id = null
        this.$store.commit('apk-versioning/SET_CURRENT_DATA', null)
        this.$bvModal.show('form-modal')
      },
      remove(item ){
        this.$swal({
          title: 'Anda yakin?',
          text: `Data APK Versioning ini akan dihapus`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            const payload = {
              id: item.id,
              fungsi: 2
            }
            this.loading = true
            this.$store.dispatch('apk-versioning/save', payload)
            .then(() => {
              this.loading = false
              this.displaySuccess({
                message: 'Data berhasil dihapus'
              })
              this.getData()
            })
            .catch(e => {
              this.loading = false
              this.displayError(e)
              return false
            })
          }
        })
      },
      async submit(fd) {
        if(this.id) {
          fd.append('id', this.id)
        }

        try {
          this.loading = true
          this.$bvModal.hide('form-modal')
          await this.$store.dispatch('apk-versioning/save', fd)
          this.loading = false
          this.displaySuccess({
            message: 'APK Versioning berhasil disimpan'
          })
          this.getData()
        }
        catch(e) {
          this.loading = false
          this.$bvModal.show('form-modal')
          this.displayError(e)
          return false
        }
      },
      edit(item) {
        this.$store.commit('apk-versioning/SET_CURRENT_DATA', item)
        this.id = item.id;
  
        this.$bvModal.show('form-modal')
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async getData() {
        const params = {order: 'desc'}
        this.loading = true
        this.items = await this.$store.dispatch('apk-versioning/getData', params)
        this.loading = false
      }
    },
    created() {
      this.getData()
    }
  }
  </script>
  